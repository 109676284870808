import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import type { WidgetInstallationType } from '@wix/platform-editor-sdk';

const presets = {
  activityShortList: {
    desktop: 'variants-l4h11t3a1',
  },
  activityFullList: {
    desktop: 'variants-l4i9aod1',
  },
  marketShortList: {
    desktop: 'variants-l4icfmdt',
  },
  stats: {
    desktop: 'variants-l4gxoa2j',
  },
};

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  console.log('Guild Energy ⚡️ and Guild Coins provided 🪙', editorSDK, appDefId, options, flowAPI);
};
