
  var editorScriptEntry = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://b64d739c861343bf953f330abeaf3e40@sentry.wixpress.com/3711',
        id: 'b64d739c861343bf953f330abeaf3e40',
        projectName: 'guild-coins-blocks',
        teamName: 'guild-coins',
        errorMonitor: true,
      };
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
      const defaultTranslations = {"app.page.dashboard":"Dashboard","app.page.activities":"Activities"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'guild-coins-blocks',
        appDefId: 'dc9a3c4d-74d2-4ccf-9fa7-677b75403744',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityList/editor.controller.ts');

      const model_0 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityList/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-wn2t4";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"dc9a3c4d-74d2-4ccf-9fa7-677b75403744-wn2t4": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-wn2t4", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityListFull/editor.controller.ts');

      const model_1 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/activityListFull/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-dcei3";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"dc9a3c4d-74d2-4ccf-9fa7-677b75403744-dcei3": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-dcei3", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/coinsList/editor.controller.ts');

      const model_2 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/coinsList/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-tpn5y";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"dc9a3c4d-74d2-4ccf-9fa7-677b75403744-tpn5y": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-tpn5y", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);
  
      const userController_3 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/stats/editor.controller.ts');

      const model_3 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/stats/model.ts').default;

      const manifest_3 = userController_3 ? userController_3.default || userController_3 : {};
      if (!manifest_3.type) {
        manifest_3.type = "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-ujudi";
      }
      if (!manifest_3.getExports && model_3) {
        manifest_3.getExports = () => ({"dc9a3c4d-74d2-4ccf-9fa7-677b75403744-ujudi": model_3.getExports()});
      }
      if (userController_3 && userController_3.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-ujudi", getWidgetManifest: userController_3.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_3);
  
      const userController_4 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/statsMini/editor.controller.ts');

      const model_4 = require('/home/builduser/work/fe12d179bbd238e1/packages/guild-coins-blocks/src/components/statsMini/model.ts').default;

      const manifest_4 = userController_4 ? userController_4.default || userController_4 : {};
      if (!manifest_4.type) {
        manifest_4.type = "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-zf24o";
      }
      if (!manifest_4.getExports && model_4) {
        manifest_4.getExports = () => ({"dc9a3c4d-74d2-4ccf-9fa7-677b75403744-zf24o": model_4.getExports()});
      }
      if (userController_4 && userController_4.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "dc9a3c4d-74d2-4ccf-9fa7-677b75403744-zf24o", getWidgetManifest: userController_4.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_4);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
